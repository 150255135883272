import * as api from '@apis/common';
import { ReactComponent as CameraIcon } from '@assets/images/app/camera.svg';
import { ReactComponent as QuotesIcon } from '@assets/images/editor/format_quote.svg';
import { ReactComponent as BubblePoint } from '@assets/images/editor/bubble-point-bottom.svg';
import { ReactComponent as BubbleClose } from '@assets/images/editor/bubble-close.svg';
import { ReactComponent as PhotoIcon } from '@assets/images/editor/photograph.svg';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import Blockquote from '@tiptap/extension-blockquote';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import StarterKit from '@tiptap/starter-kit';
import { Content, EditorContent, HTMLContent, useEditor } from '@tiptap/react';
import React, { CSSProperties, useEffect, useState } from 'react';
import LinkPreview from './LinkPreview';
import FileUploadButton from '@components/app/common/FileUploadButton';
import { SortableImagesContainer } from './SortableImagesContainer';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { URL_PATTERN } from '@components/Linkify/constants';

export interface LoungeEditorProps {
  board: string; // review, curation
  value?: Content;
  showToolbar?: boolean;
  onChange?: (html: HTMLContent, originHtml: HTMLContent) => void;
  attachFilesLimit?: number;
  images?: string[];
  imagesTemp?: string[];
  onChangeImageFiles?: (files: FileList) => void;
  onChangeImages?: (images: string[], imagesTemp: string[]) => void;
}

const PlaceHolder: React.FC<{ style?: CSSProperties; board?: string }> = (props) => {
  return (
    <div style={props.style}>
      {props.board === 'review' && (
        <div>
          <div
            style={{
              color: 'var(--Neutral-50, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            제목을 입력하세요.(30자 이내)
          </div>
          <div
            style={{
              color: 'var(--Neutral-50, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              marginTop: '16px',
              lineHeight: '28px',
              whiteSpace: 'pre-wrap',
            }}>
            {'다른 부모님들과 나눌 수 있는 후기를 작성해주세요.\n건강한 라운지 문화조성에 참여해주셔서 감사합니다.'}
          </div>
          <div
            style={{
              color: '#FC4242',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              marginBottom: '16px',
              lineHeight: '28px',
            }}>
            최소 30자 이상 입력해주세요.
          </div>
          <div
            style={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              background: 'var(--Primary-5, #FFF5F9)',
              color: 'var(--Primary-95, #FF3D8F)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              padding: '10px',
            }}>
            <PhotoIcon />
            <span>포토후기 작성 시</span>
            <span style={{ fontWeight: 700 }}>+3% 적립!</span>
          </div>
        </div>
      )}
      {props.board === 'curation' && (
        <div>
          <div
            style={{
              color: 'var(--Neutral-50, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            제목을 입력하세요.
          </div>
          <div
            style={{
              color: 'var(--Neutral-50, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              marginTop: '16px',
              lineHeight: '28px',
              whiteSpace: 'pre-wrap',
            }}>
            {'내용에는 URL이 반드시 포함되어야 합니다.\n예) https://www.naver.com'}
          </div>
        </div>
      )}
    </div>
  );
};

export const LoungeEditor: React.FC<LoungeEditorProps> = (props) => {
  const [showPhotoTooltip, setShowPhotoTooltip] = useState<boolean>(false);
  const {
    showToolbar,
    board,
    value,
    onChange,
    attachFilesLimit,
    images,
    imagesTemp,
    onChangeImageFiles,
    onChangeImages,
  } = props;
  const dialog = useOkCancelDialog();
  const CustomDocument = Document.extend({
    content: 'heading block*',
  });
  const currentImages = imagesTemp !== undefined && imagesTemp?.length > 0 ? imagesTemp : images || [];

  const isReviewEdit = board === 'review';

  useEffect(() => {
    setShowPhotoTooltip(isReviewEdit && (images || []).length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, images]);

  const extensions = [
    CustomDocument,
    StarterKit.configure({
      document: false,
    }),
    Paragraph.configure({
      HTMLAttributes: {
        class: 'lounge-editor-paragraph',
      },
    }),
    Text,
    Blockquote.configure({
      HTMLAttributes: {
        class: 'lounge-editor-blockquote',
      },
    }),
    LinkPreview,
  ];

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: (event) => {
      onChange?.(event.editor.view.dom.innerHTML, event.editor.getHTML());
    },
    onPaste: (event, slice) => {
      const content: any = slice.content as any;
      const urlMatches = content.content[0].textContent.match(URL_PATTERN);
      if (urlMatches) {
        const url = urlMatches[0];
        api.getUrlPreview(url).then((response) => {
          const title = response.data.ogTitle;
          const imageUrl = response.data.ogImage?.[0]?.url;
          const description = response.data.ogDescription;
          const ogUrl = response.data.ogUrl;

          const doc = editor?.getHTML();
          const linkPreview = `<div contenteditable="false" link-preview="link-preview" image-url="${imageUrl}" title="${title}" description="${description}" link="${url}" og-url="${ogUrl}"></div>`;
          if (doc) {
            editor?.commands.setContent(doc + linkPreview);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (value === '') {
      editor?.commands.clearContent();
    } else if (value) {
      if (value !== editor?.getHTML()) {
        editor?.commands.setContent(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = currentImages.findIndex((item) => item === active.id);
      const newIndex = currentImages.findIndex((item) => item === over?.id);
      const newDetailImages = arrayMove(images || [], oldIndex, newIndex);
      const newDetailImagesTemp = arrayMove(currentImages, oldIndex, newIndex);
      const newImages = [...newDetailImages];
      const newImagesTemp = [...newDetailImagesTemp];

      onChangeImages?.(newImages, newImagesTemp);
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 104px)', overflow: 'auto' }}
      onClick={(e) => {
        if ((e.target as any).className === 'tiptap ProseMirror ProseMirror-focused') {
          if (
            value &&
            value !== '' &&
            value !== '<h1></h1>' &&
            !value?.toString().endsWith('<p class="lounge-editor-paragraph"></p>')
          ) {
            editor?.commands.enter();
          }
        }
      }}>
      {(!value || value === '' || value === '<h1></h1>') && (
        <PlaceHolder
          board={props.board}
          style={{ position: 'absolute', padding: '33px 20px', zIndex: 0, width: '100%' }}
        />
      )}
      <EditorContent
        editor={editor}
        style={{ flexGrow: 1, outline: 'none', padding: '20px', background: 'transparent' }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '1px solid #E8EAED',
          gap: '8px',
          padding: '14px 16px',
          marginTop: 'auto',
        }}>
        {currentImages.length > 0 && (
          <div style={{ marginBottom: '12px', marginTop: '8px' }}>
            <SortableImagesContainer
              images={currentImages}
              onDragEnd={onSortEnd}
              onDeleteImage={(index) => {
                const newImages = [...(images || [])];
                newImages.splice(index, 1);
                const newImagesTemp = [...(imagesTemp || [])];
                newImagesTemp.splice(index, 1);

                onChangeImages?.(newImages, newImagesTemp);
              }}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <FileUploadButton
            multiple
            accept={['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.webp'].join(',')}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                const limitCount = (images?.length || 0) + files.length;
                if (attachFilesLimit && limitCount > attachFilesLimit) {
                  dialog.open({
                    content: `이미지는 5장까지 첨부할 수 있습니다`,
                    type: 'ok',
                  });

                  return;
                }
                onChangeImageFiles?.(files);
              }
            }}
            className="link-button">
            <div
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                position: 'relative',
              }}>
              {showPhotoTooltip && (
                <div style={{ width: '180px', position: 'absolute', bottom: '32px', left: '-8px' }}>
                  <div
                    style={{
                      backgroundColor: '#FF3D8F',
                      borderRadius: '4px',
                      padding: '8px 12px',
                      display: 'flex',
                      gap: '8px',
                    }}>
                    <div
                      style={{
                        color: 'var(--White-100, #FFF)',
                        fontFamily: 'Pretendard',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '18px',
                        textAlign: 'left',
                      }}>
                      {'상품과 무관한 사진을 첨부한 후기는 통보없이 삭제 및 적립 혜택이 회수됩니다.'}
                    </div>
                    <div>
                      <button
                        className="link-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowPhotoTooltip(false);
                        }}>
                        <BubbleClose />
                      </button>
                    </div>
                  </div>
                  <BubblePoint style={{ display: 'block' }} />
                </div>
              )}
              <CameraIcon />
              <div>사진 첨부</div>
              {attachFilesLimit && (
                <div>
                  <span
                    style={{
                      color: 'var(--Primary-95, #FF3D8F)',
                      fontFamily: 'Pretendard',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}>
                    {currentImages.length}
                  </span>
                  {` / ${attachFilesLimit}`}
                </div>
              )}
            </div>
          </FileUploadButton>
          {showToolbar && (
            <button
              className="link-button"
              style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: '40px', height: '36px' }}
              onClick={() => {
                editor?.commands.toggleBlockquote();
              }}>
              <QuotesIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoungeEditor;
