import React, { useEffect } from 'react';

interface RedirectToExternalProps {
  url: string;
}

export const RedirectToExternal: React.FC<RedirectToExternalProps> = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <div></div>;
};

export default RedirectToExternal;
